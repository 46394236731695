import React, { useState, useCallback, useRef  } from "react";
import "./Login.scss";
import up42Services from "../../api/up42Services";
import { useAlert } from "../Alert/useAlert";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link } from "react-router-dom"; 
import ChangePasswordPopup from "../HelpMenu/ChangePasswordPopup";

const LoginComponent = ({ loginAction, setUserId, onShowPrivacyPolicy  }) => {

    const showAlert = useAlert();

     //const [userName, setUserName] = useState("Sanzar@example.com");
     //const [password, setPassword] = useState("Password123.");

   const [userName, setUserName] = useState("");
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [completeName, setcompleteName] = useState("");
   const [error, setError] = useState(""); 
   const [errorRegister, setErrorRegister] = useState("");
   const [invitationtoken, setInvitationToken] = useState("");
   const [loading, setLoading] = useState(false);
   const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);
   const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
   const [openChangePasswordPopup, setOpenChangePasswordPopup] = useState(false);
   const containerRef = React.useRef(null);


    const openSignInForm = () => {
        // const container = document.getElementsByClassName('login-container')[0];
        // container.classList.remove("right-panel-active");
        if (containerRef.current) {
            containerRef.current.classList.remove("right-panel-active");
        }
    }

    const openSignUpForm = () => {
        setUserName('');
        setPassword('');
        setConfirmPassword('');
        setcompleteName('');
        // const container = document.getElementsByClassName('login-container')[0];
        // container.classList.add("right-panel-active");
        if (containerRef.current) {
            containerRef.current.classList.add("right-panel-active");
        }
    }

    const onChangeCompleteName = (val) => {
        setcompleteName(val.target.value);
    }
    
    const onChangeUserName = (val) => {
        setUserName(val.target.value);
    }

    const onChangePassword = (val) => {
        setPassword(val.target.value);
    }
    const onChangeConfirmPassword = (val) => {
        setConfirmPassword(val.target.value);
    }

    const onChangeInvitationToken = (val) => {
        setInvitationToken(val.target.value);
    }

    const onClickShowPrivacyPolicy = () =>{
        setShowPrivacyPolicy(!showPrivacyPolicy)
    }
    
    //david update
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
   //const API_BASE_URL = "https://backendwebmapv1.azurewebsites.net";
   //const API_BASE_URL = "https://localhost:7284";

   const login = async (userName, Password) => {
 
       // loginAction("Logindisabledfornow");
        try {
            const response = await fetch(`${API_BASE_URL}/api/Auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userName, password: Password }),
            });
    
            // const data = await response.json();
            if (response.ok) {
                const data = await response.json();
                showAlert('Successfully Login the System', 'success');
                loginAction(data); // Asumiendo que 'data' contiene la información necesaria para tu aplicación
                //setUserId(data.userId);
            } else {
               // showAlert('Wrong e-mail or Password', 'error');
   
                setError("Wrong email or password.");
            }
        } catch (error) {
            showAlert('Couldnt Connect to Server', 'error');
            console.error("Error fetching data:", error);
        }
    };

 const signIn = () => {
     login(userName, password);

    }

const register = async (e) => {

    if (password !== confirmPassword) {
        setErrorRegister("Passwords do not match.");
        return;
    }
        
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;

    
    if (!passwordRegex.test(password)) {
        setErrorRegister("La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula, un número y un carácter especial.");
        return;
    } else {
        setErrorRegister("");
    }

    // loginAction("Logindisabledfornow");
    try {

        setLoading(true);

        const response = await fetch(`${API_BASE_URL}/api/Auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userName,
                password: password,
                confirmPassword: confirmPassword,
                completeName: completeName,
                invitationToken: invitationtoken,
                agreePrivacyPolicy: agreePrivacyPolicy
            }),
        });

        setLoading(false); 

        if (response.ok) {
            //const data = await response.json();
            showAlert('Successfully Registered', 'success');
            //loginAction(data);
             
              // Cambia a la vista de inicio de sesión
              openSignInForm();
        } else {
            const errorData = await response.json();
            if (errorData.error) {
                setErrorRegister(errorData.error);
            } else {
                setErrorRegister(errorData.message || "Registration failed.");
            }
        }
    } catch (error) {
        setLoading(false); 
        showAlert('Could not connect to server', 'error');
        console.error("Error fetching data:", error);
    }
};
// const singUp= () =>{
//     register(userName,password,confirmPassword,completeName);
// }

    return (
        <div className="main-login-container">
            <div className="login-container" ref={containerRef}>
                <div className="form-container sign-up-container">
                    <form className="login-form" id="registerForm" action="#" onSubmit={(e) => { e.preventDefault(); register(); }}>
                        <h1 className="login-h1">Create Account</h1>
                        <input className="login-input" type="text" placeholder="Name" value={completeName} onChange={onChangeCompleteName}/>
                        <input className="login-input" type="email" placeholder="Email" value={userName} onChange={onChangeUserName} />
                        <input className="login-input" type="password" id="password" placeholder="Password" value={password} onChange={onChangePassword}/>
                        <input className="login-input" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={onChangeConfirmPassword}/>
                        <input className="login-input" type=""  placeholder="Token" value={invitationtoken} onChange={onChangeInvitationToken}/>
                        <FormControlLabel required control={<Checkbox />} label="I have read and accept the privacy policy" /> 
                        <button type="button" onClick={() => {onShowPrivacyPolicy(); }} onChange={(e) => setAgreePrivacyPolicy(e.target.checked)} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', fontSize: 15 }}>
                            Privacy Policy
                        </button>
                        {errorRegister && <Alert severity="error" style={{ marginBottom: '20px' }}>{errorRegister}</Alert>}
                        {loading ? (
                                <CircularProgress /> // Indicador de carga
                            ) : (
                                <button type="submit" className="login-btn">Register</button>
                        )}
                    </form>
                </div>

                <div className="form-container sign-in-container">
                    <form className="login-form" action="#" onSubmit={(e) => { e.preventDefault(); signIn(); }}>
                        <h1 className="login-h1">Login</h1>
                        <input className="login-input" type="email" placeholder="Email" value={userName} onChange={onChangeUserName} />
                        <input className="login-input" type="password" placeholder="Password" value={password} onChange={onChangePassword} ></input>
                        {error && <Alert severity="error" style={{ marginBottom: '20px' }}>{error}</Alert>}
                        <a className="login-link" href="#" onClick={() => setOpenChangePasswordPopup(true)}>Forgot your password?</a>
                        <button className="login-btn" >Login</button>
                    </form>
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-left">
                            <h1 className="login-h1">Welcome Back!</h1>
                            <p className="login-p">To keep connected with us please login with your personal info</p>
                            <button className="login-btn ghost" id="signIn" onClick={openSignInForm}>Login</button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <h1 className="login-h1">Sanzar Group</h1>
                            <p className="login-p">Enter your personal details and start journey with us</p>
                            <button className="login-btn ghost" id="signUp" onClick={openSignUpForm}>Register</button>
                        </div>
                    </div>
                </div>
            </div>
            <ChangePasswordPopup open={openChangePasswordPopup} onClose={() => setOpenChangePasswordPopup(false)} />
            <footer>
                <p className="login-p">
                    <a target="_blank" href="https://www.sanzar-group.com/">Sanzar Group</a>
                </p>
            </footer>
            
        </div>
    );
}

export default React.memo(LoginComponent);