import { useEffect, useState, useContext,useRef } from 'react';
import { Map, View } from 'ol';
import { Group as LayerGroup, Tile as TileLayer2, Image as ImageLayer } from 'ol/layer.js';
//import { TileLayer as WebGLTile } from 'ol/layer/WebGLTile';
import WebGLTileLayer from 'ol/layer/WebGLTile.js';
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps.js';
import StadiaMaps from 'ol/source/StadiaMaps';
import MapContextProvider from '../../context/MapContextProvider';
import { defaults as defaultControls, MousePosition, ScaleLine, Zoom } from 'ol/control';
import { createStringXY } from 'ol/coordinate';
import GeoTIFF from 'ol/source/GeoTIFF.js';
import { GPX, GeoJSON, IGC, KML, TopoJSON } from 'ol/format.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import { Draw, Modify, Snap } from 'ol/interaction.js';
// import up42Services from '../../api/up42Services';
import { getAccessToken, getObjectList } from '../../api/AxiosHelper';
// import TreesLayer from "../../assets/data/trees.json";
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import XYZ from 'ol/source/XYZ.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import TileWMS from 'ol/source/TileWMS.js';
import DragAndDrop from 'ol/interaction/DragAndDrop.js';
import { AppContext } from "../../context/AppContextProvider";
import { isMobile } from 'react-device-detect';
import "./map.scss"
import Legend from 'ol-ext/legend/Legend';
import LegendControl from 'ol-ext/control/Legend';
import 'ol-ext/dist/ol-ext.css'; 
import LegendImage from 'ol-ext/legend/Image';


//david test
import TileLayer from 'ol/layer/Tile';
import { Padding } from '@mui/icons-material';



//const mapboxKey = "pk.eyJ1IjoidHlha2FyIiwiYSI6ImNsbXI5dGFkbDAwZnMydHA3M2Fja3M5YXMifQ.cXS9KGt2ypTqkJ3Y5cPqIQ";
const bingKey = process.env.REACT_APP_API_BING_MAPS;
//const geoserverPath = process.env.REACT_APP_GEOSERVER;

let dragAndDropInteraction;

const MapComponent = ({ mapOptions, layersFromProject, children }) => {

  // const mapRef = useRef();
  const [olMap, setOlMap] = useState();
  const [layerDropped, setLayerDropped] = useState({});
  const {setActiveLayerId} = useContext(AppContext);
  const [isAerialVisible, setIsAerialVisible] = useState(true);
  const legendUrlBase = 'https://sanzargroupgeo.es/geoserver/sanzarGeoTIFF/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=sanzarGeoTIFF:';
  const getLegendUrl = (layerName) => {
    if (!layerName || layerName === 'Sensors') {
      console.warn('La capa "Sensors" no tiene leyenda asociada.');
      return null;
    }
    if (layerName.includes('&')) {
      layerName = layerName.replace(/&/g, '%26');
    }
    return `${legendUrlBase}${layerName}&WIDTH=25&HEIGHT=25&LEGEND_OPTIONS=fontSize:16;bgColor:0xFFFFFF;forceLabels:on`;
  };
  const [legendUrl, setLegendUrl] = useState('');
  const legendCtrlRef = useRef(null);


  // useEffect(()=>{

  //   console.log("layersfromproyect desde MAPCOMPONENT",layersFromProject)
  // },[layersFromProject]);

  useEffect(() => {
    if (!olMap) return;  // Si el mapa aún no está inicializado, no hacer nada

    const layerNames = ["Critical Health Trees", "Critical Hydric Stress", "Produc.&Count."];

    // Encuentra las capas por nombre
    const layers = layerNames.map(name => {
      let foundLayer = null;
      olMap.getLayers().forEach(group => {
        if (group instanceof LayerGroup) {
          group.getLayers().forEach(layer => {
            if (layer.get('title') === name) {
              foundLayer = layer;
            }
          });
        }
      });
      return foundLayer;
    });

    // Escucha el evento 'change:visible' en cada capa
    layers.forEach((layer, index) => {
      if (layer) {
        layer.on('change:visible', () => {
          if (layer.getVisible()) {
            // Si la capa se hace visible, hacer las otras capas invisibles
            layers.forEach((otherLayer, otherIndex) => {
              if (otherIndex !== index && otherLayer.getVisible()) {
                otherLayer.setVisible(false);
              }
            });
          }
        });
      }
    });
  }, [olMap]); 

  useEffect(() => {
    // var transform32630 = '+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs';
    // var transform32618 = '+proj=utm +zone=18 +datum=WGS84 +units=m +no_defs +type=crs';
    // proj4.defs('EPSG:32630', transform32630);
    // proj4.defs('EPSG:32618', transform32618);

    // register(proj4);

    //let baseMaps = addBaseLayers();

      //david test

    // let layerOlivosJaen = createWMSLayer({
    //   url: 'https://sanzargroupgeo.es/geoserver/ne/wms?',
    //   layers: 'ne:olivos_jaen',
    //   title: 'Olives Jaen sz',
    //   // extent: [-13884991, 2870341, -7455066, 6338219]
    // });

    // const addBingMap = () => new TileLayer({
    //   visible: true,
    //   source: new BingMaps({
    //     key: bingKey,
    //     imagerySet: 'Aerial',
    //   }),
    //   title: 'Bing Aerial',
    //   baseLayer: true,
    //   properties: {
    //     displayInLayerSwitcher: true // Propiedad personalizada o usar una ya existente dependiendo de tu implementación
    //   }
    // });

    const addBingMapLayers = () => {
      // Crear capa Aerial
      const aerialLayer = new TileLayer({
        visible: true, // Visible inicialmente
        source: new BingMaps({
          key: bingKey,
          imagerySet: 'Aerial',
        }),
        title: 'Satellite',
        baseLayer: true,
      });
    
      // Crear capa Street
      const streetLayer = new TileLayer({
        visible: false, // No visible inicialmente
        source: new BingMaps({
          key: bingKey,
          imagerySet: 'Road',
        }),
        title: 'Street',
        baseLayer: true,
      });
    
      return [aerialLayer, streetLayer];
    };
    
    const [aerialLayer, streetLayer] = addBingMapLayers();

    const scaleLineControl = new ScaleLine(); 
    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:4326',
      className: 'custom-mouse-position',
      target: document.getElementById('coordinates'),
      undefinedHTML: '&nbsp;'
    });

    const controls = defaultControls({
      attribution: false,
      zoom: !isMobile 
    }).extend([
      scaleLineControl,
      mousePositionControl
    ]);


    // Inicialización del objeto Map de OpenLayers.  
    const olMap = new Map({
        target: 'map',
        layers: [aerialLayer, streetLayer],
        // layers: [addBingMap()],
        controls: controls,
      view: new View({
        // projection: 'EPSG:4326',
        projection: 'EPSG:3857',
        //center: [-1826213,  3301025], //coordenadas tif Tenerife. 
        center: [-431361, 4539602], //Coordenadas tif olivios jaen. 
        zoom: 14,
      }),      

    });
 


    // Configuración de interacciones, como arrastrar y soltar.
    setInteraction(olMap);
    window.initMap = olMap;
    setOlMap(olMap); // Actualizar el estado con el mapa inicializado.

    const handleToggleLayers = () => {
      setIsAerialVisible(!isAerialVisible);
    };

    const checkbox = document.getElementById('toggle-layers-checkbox');
    if (checkbox) {
      checkbox.addEventListener('change', handleToggleLayers);
    }

    return () => {
      if (checkbox) {
        checkbox.removeEventListener('change', handleToggleLayers);
      }
    };

  }, []); // Dependencias vacías para ejecutar solo en el montaje.

  useEffect(() => {
    if (!olMap) return;

    const aerialLayer = olMap.getLayers().getArray().find(layer => layer.get('title') === 'Satellite');
    const streetLayer = olMap.getLayers().getArray().find(layer => layer.get('title') === 'Street');

    if (aerialLayer && streetLayer) {
      aerialLayer.setVisible(isAerialVisible);
      streetLayer.setVisible(!isAerialVisible);
    }


  }, [isAerialVisible, olMap]);

  // Define interacciones para el mapa, como arrastrar y soltar.
  function setInteraction(map) {
    if (dragAndDropInteraction) {
      map.removeInteraction(dragAndDropInteraction);
    }
    dragAndDropInteraction = new DragAndDrop({
      formatConstructors: [
        // GPX,
        GeoJSON,
        // IGC,
        // use constructed format to set options
        new KML({ extractStyles: true }),
        TopoJSON,
      ],
    });
    dragAndDropInteraction.on('addfeatures', function (event) {
      const vectorSource = new VectorSource({
        features: event.features,
      });
      map.addLayer(
        new VectorLayer({
          title: event.file.name,
          source: vectorSource
        })
      );
      map.getView().fit(vectorSource.getExtent());
    });
    map.addInteraction(dragAndDropInteraction);
  }//END setInteraction function


  //Función para crear geotifflayers. Actualmente no se esta usando. 
  const createGeotifLayer = ({ url, title, visible = false }) => {

    const source = new GeoTIFF({
      // cacheSize: 10000,
      projection: 'EPSG:32630',
      sources: [
        {
          url: url,
          allowFullFile: true
        },
      ],
    });

    let layer = new WebGLTileLayer({
      source: source,
      title: title,
      visible: visible
    });

    layer.canSwipe = true;

    return layer;
  }//END


  let currentZIndex = 500; // Inicializa con un valor base

  const getNextZIndex = () => {
      return currentZIndex++;
  }
  //Función para crear capas basado en WMS
  const createWMSLayer = ({ url, title, layers, visible = true, extent }) => {
    const index = title.indexOf("sanzarGeoTIFF:");
    const modifiedTitle = index !== -1 ? title.substring(index + "sanzarGeoTIFF:".length) : title;
    const shouldBeVisible = !["Critical Health Trees", "Critical Hydric Stress", "Produc.&Count."].includes(modifiedTitle);
    
    const layer = new TileLayer2({
      visible: shouldBeVisible,
      title: modifiedTitle,
      zIndex: getNextZIndex(),
      source: new TileWMS({
        url: url,
        params: { 'LAYERS': layers},
        ratio: 1,
        serverType: 'geoserver',
      }),
      properties: {
        displayInLayerSwitcher: !modifiedTitle.includes("_info") // Añade esta propiedad personalizada
      }
    });

    layer.canSwipe = true;

  //   layer.on('change:visible', () => {
  //     updateActiveLayer();
  // });
  layer.on('change:visible', () => handleLayerVisibilityChange(layer, ["Critical Health Trees", "Critical Hydric Stress", "Produc.&Count."]));
    return layer;
  }//END

  const handleLayerVisibilityChange = (layer, exclusiveLayerNames) => {
    const layerTitle = layer.get('title');
    const infoLayerTitle = `${layerTitle}_info`;

    if (layer.getVisible()) {
        olMap.getLayers().forEach(group => {
            if (group instanceof LayerGroup) {
                group.getLayers().forEach(otherLayer => {
                    if (otherLayer !== layer && exclusiveLayerNames.includes(otherLayer.get('title'))) {
                        otherLayer.setVisible(false);
                    }
                });
            } else {
                if (group !== layer && exclusiveLayerNames.includes(group.get('title'))) {
                    group.setVisible(false);
                }
            }
        });

        // Si la capa principal se hace visible, asegura que la capa _info asociada también se muestre
        olMap.getLayers().forEach(group => {
            if (group instanceof LayerGroup) {
                group.getLayers().forEach(otherLayer => {
                    if (otherLayer.get('title') === infoLayerTitle) {
                        otherLayer.setVisible(true);
                    }
                });
            } else {
                if (group.get('title') === infoLayerTitle) {
                    group.setVisible(true);
                }
            }
        });
    } else {
        // Si la capa principal se hace invisible, oculta también la capa _info asociada
        olMap.getLayers().forEach(group => {
            if (group instanceof LayerGroup) {
                group.getLayers().forEach(otherLayer => {
                    if (otherLayer.get('title') === infoLayerTitle) {
                        otherLayer.setVisible(false);
                    }
                });
            } else {
                if (group.get('title') === infoLayerTitle) {
                    group.setVisible(false);
                }
            }
        });
    }

    updateActiveLayer();
};


  //Crea capas sin uso de tiles, actualmente no se esta usando
  const createWMSSingleTileLayer = ({ url, title, layers, visible = true, extent }) => {
    const layer = new ImageLayer({
      visible: visible,
      title: title,
      source: new ImageWMS({
        url: url,
        params: { 'LAYERS': layers },
        ratio: 1,
        serverType: 'geoserver',
      })
    });

    layer.canSwipe = true;
    return layer;
  }//END

  //añade la función de dibujar poligonos en el mapa, pero no esta implementado. 
  const addDraw = (map, source) => {

    const modify = new Modify({ source: source });
    let draw = new Draw({
      source: source,
      type: 'Polygon',
    });
    map.addInteraction(draw);
    let snap = new Snap({ source: source });
    map.addInteraction(snap);

    map.addInteraction(modify);
  }//END

  //Grupo de capas base
  // const addBaseLayers = () => {

  //   let layers = [];
  //   // AerialWithLabelsOnDemand
  //   layers.push(
  //     addBingMap("Aerial", "Bing Aerial", bingKey,true)
  //   )
  //   layers.push(
  //     addBingMap("RoadOnDemand", "Bing Road", bingKey)
  //   )

  //   layers.push(new TileLayer2({
  //     title: "OSM",
  //     className: "OSM",
  //     baseLayer: true,
  //     source: new OSM(),
  //     visible: false
  //   }))

  //   return new LayerGroup({
  //     title: 'Base Layers',
  //     openInLayerSwitcher: true,
  //     layers: layers
  //   });
  // }//END
  

  const addBingMap = (type, title, key, visible = false) => {
    return new TileLayer2({
      visible: visible,
      preload: Infinity,
      source: new BingMaps({
        key: key,
        imagerySet: type,
        // placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
      }),
      title: title,
      baseLayer: true,
    })
  }

  // Manejo de capas dinámicas
  const addYourLayers = (layersFromProject) => {

    // const dynamicLayers = layersFromProject.map(layerInfo => createWMSLayer({
    //   url: layerInfo.geoServerLayerURL,
    //   layers: layerInfo.geoServerLayerName,
    //   title: layerInfo.geoServerLayerName,
    //   // otras configuraciones...
    // }));
    const dynamicLayers = layersFromProject.map(layerInfo => {
      const layer = createWMSLayer({
          url: layerInfo.geoServerLayerURL,
          layers: layerInfo.geoServerLayerName,
          title: layerInfo.geoServerLayerName,
      });

      // Asumiendo que tienes un método para manejar el cambio de capa activa
      layer.on('change:visible', (e) => {
        console.log(`Visibilidad cambiada: ${layer.get('title')}, Visible: ${layer.getVisible()}`);
        updateActiveLayer();
      });

      return layer;
    });
    
    const yourLayersGroup = new LayerGroup({
      layers: dynamicLayers,
      openInLayerSwitcher: true,
      title: 'Your Layers', // Este título es más para tu referencia; necesitarás un switcher de capas que lo utilice.
    });

    return yourLayersGroup;
  }

  const updateActiveLayer = () => {
    let topLayer = null;
    let maxZIndex = -1;
  
    // Recorrer todas las capas del mapa, incluyendo subcapas de grupos
    olMap.getLayers().forEach(group => {
      if (group instanceof LayerGroup) {
        group.getLayers().forEach(layer => {
          if (layer.getVisible() &&
              layer.get('title') !== 'Sensors' &&
              !layer.get('properties')?.isLegendExempt && 
              layer.get('title') !== 'Measurement' &&
              layer.get('title') !== 'Draw Projects') {
            const zIndex = layer.getZIndex(); // Asegurarse que las capas tengan un zIndex definido
            if (zIndex > maxZIndex) {
              topLayer = layer;
              maxZIndex = zIndex;
            }
          }
        });
      } else if (group.getVisible() &&
                 group.get('title') !== 'Sensors' &&
                !group.get('properties')?.isLegendExempt && 
                group.get('title') !== 'Measurement') {
        const zIndex = group.getZIndex();
        if (zIndex > maxZIndex) {
          topLayer = group;
          maxZIndex = zIndex;
        }
      }
    });
  
    // Actualizar el ID de la capa activa basado en la capa visible superior
    if (topLayer) {
      setActiveLayerId(topLayer.get('title'));
      setLegendUrl(getLegendUrl(topLayer.get('title')));
      console.log(`legendUrl: '${legendUrl}'`);
    }
  }

  useEffect(() => {
    if (!olMap || !layersFromProject) return;

    // Intentamos encontrar y remover el grupo de capas anterior para evitar duplicaciones
    const existingGroup = olMap.getLayers().getArray().find(layer => layer.get('title') === 'Your Layers');
    if (existingGroup) {
      olMap.removeLayer(existingGroup);
    }

    const yourLayersGroup = addYourLayers(layersFromProject);

    // Añadimos el nuevo grupo de capas al mapa
    olMap.addLayer(yourLayersGroup);

    updateActiveLayer();//nos aseguramos de que se actualice el id de la capa activa, inmediatamente depues de añadir las capas.

  }, [layersFromProject, olMap]);
  //END Manejo de capas dinámicas

  useEffect(() => {
    if (!olMap) return;

    const legend = new Legend({
      margin: 5,
      maxWidth: 500
    });

    const legendControl = new LegendControl({
      legend: legend,
      collapsed: false,
      className: 'ol-legend'
    });

    legendCtrlRef.current = legendControl;
    olMap.addControl(legendControl);

    return () => {
      if (legendCtrlRef.current) {
        olMap.removeControl(legendCtrlRef.current);
        legendCtrlRef.current = null;
      }
    };
  }, [olMap]);

  useEffect(() => {
    if (legendUrl && olMap) {
      const legendControl = legendCtrlRef.current;
      if (legendControl) {
        const items = legendControl.getLegend().getItems();
      // Remover cada item de la leyenda
       items.forEach(item => legendControl.getLegend().removeItem(item));
        const newLegend = new Legend({ title: "", margin: 0 });
        newLegend.addItem(new LegendImage({
          title: 'Data:',
          src: legendUrl
        }));
        legendControl.getLegend().addItem(newLegend);
      }
    }
  }, [legendUrl, olMap]);


  //useEffect que toma la información de una capa al hacer singleclic sobre ella.
  useEffect(() => {
    if (!olMap) return;
  
    const handleMapClick = async (event) => {
      const coordinate = event.coordinate;
      const viewResolution = olMap.getView().getResolution();
      const layers = olMap.getLayers().getArray();
    
      let wmsLayer = null;
      let topLayerName = '';
    
      // Encuentra la capa WMS visible y captura el nombre de la capa en la parte superior
      layers.forEach(layer => {
        if (layer instanceof LayerGroup) {
          layer.getLayers().forEach(subLayer => {
            if (subLayer.getVisible() && subLayer.getSource && subLayer.getSource() instanceof TileWMS) {
              wmsLayer = subLayer;
              topLayerName = subLayer.get('title'); // Captura el nombre de la capa
            }
          });
        } else if (layer.getVisible() && layer.getSource && layer.getSource() instanceof TileWMS) {
          wmsLayer = layer;
          topLayerName = layer.get('title'); // Captura el nombre de la capa
        }
      });
    
      if (wmsLayer) {
        const source = wmsLayer.getSource();
        const url = source.getFeatureInfoUrl(
          coordinate,
          viewResolution,
          'EPSG:3857',
          { 'INFO_FORMAT': 'application/json' }
        );
    
        if (url) {
          try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.features && data.features.length > 0) {
              const featureInfo = data.features[0].properties;
              console.log('Feature Info:', featureInfo);
              if (featureInfo.capa) {
                const styleName = featureInfo.capa; // Asumiendo que featureInfo.capa contiene el nombre del estilo
                console.log('Top Layer Name:', topLayerName);
                console.log('Feature Info Capa:', styleName);
                const newLegendUrl = `${getLegendUrl(topLayerName)}&STYLE=${styleName}`;
                console.log(`New legend URL: ${newLegendUrl}`);
                setLegendUrl(newLegendUrl);
              } else {
                console.warn('No se encontró la propiedad "capa" en los datos del feature.');
              }
            } else {
              console.warn('No se encontraron features en la respuesta.');
            }
          } catch (error) {
            console.error('Error fetching feature info:', error);
          }
        }
      } else {
        console.warn('La capa WMS no está visible.');
      }
    };
    
  
    olMap.on('singleclick', handleMapClick);
  
    return () => {
      olMap.un('singleclick', handleMapClick);
    };
  }, [olMap]);
  
  
  

  return (
    <>
      <MapContextProvider map={olMap} layerDropped={layerDropped}>
        {olMap ? children : <></>}
      </MapContextProvider>
    </>
  );
}

export default MapComponent;