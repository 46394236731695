/* eslint-disable-next-line  no-unused-vars */
import { createElement, useEffect, useRef } from 'react';
import { createContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import VectorSource from 'ol/source/Vector';
//import VectorLayer from 'ol/layer/Vector';
export const MapContext = createContext();

export default function MapContextProvider({ map, layerDropped, children }) {

    const [mapLayerList, setMapLayerList] = useState();
    const [loader, setLoader] = useState(false);
    const [droppedLayer, setDroppedLayer] = useState({});
    const [drawSource, setDrawSource] = useState(new VectorSource());
    const addLayer = (layer) => {
        map.addLayer(layer);
    };
    
    const removeLayer = (layer) => {
        map.removeLayer(layer);
    };
    
    //const [vectorLayer, setVectorLayer] = useState(null);

    useEffect(() => {
        setDroppedLayer(layerDropped);
    }, [layerDropped]);

    // useEffect(() => {
    //     if (map && !vectorLayer) {
    //         const newVectorLayer = new VectorLayer({
    //             source: drawSource,
    //         });
    //         map.addLayer(newVectorLayer);
    //         setVectorLayer(newVectorLayer);
    //         console.log("Vector layer added to the map");

    //         return () => {
    //             if (map) {
    //                 map.removeLayer(newVectorLayer);
    //                 console.log("Vector layer removed from the map");
    //             }
    //         };
    //     }
    // }, [map, drawSource]);

    return (
        <MapContext.Provider
            value={{
                map,
                mapLayerList,
                setMapLayerList,
                setLoader,
                droppedLayer,
                setDroppedLayer,
                drawSource,
                setDrawSource,
                addLayer,
                removeLayer
            }}
        >
            {loader && (
                <CircularProgress
                    size={48}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                    }}
                />
            )}
            {map !== null ? children : <></>}
        </MapContext.Provider>
    );
}
