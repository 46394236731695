import React, { useContext, useState,useEffect,useRef } from "react";
import { BottomNavigation, BottomNavigationAction, Fab, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { isMobile, isIOS } from 'react-device-detect';
import "./Menu.scss";
import { AppContext } from "../../context/AppContextProvider";
import ReportDialog from "../HelpMenu/ReportDialog";
import ModalDialog from "../ModalDialog";
import HelpDialog from "../HelpMenu/HelpDialog";
import InfoIcon from '@mui/icons-material/Info';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PolicyIcon from '@mui/icons-material/Policy';
import PrivacyPolicyModal from "../HelpMenu/PrivacyPolicyModal";
import MapIcon from '@mui/icons-material/Map';


const Navbar = () => {
    const { selectedMenu, setSelectedMenu, setIsMenuActive, isMenuActive, setLoggedIn, selectedProjectId, setSelectedProjectId, userName } = useContext(AppContext);

    const [showContact, setShowContact] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showSearcher, setShowSearcher] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [isLandscape, setIsLandscape] = useState(false);

    useEffect(()=>{
      console.log(`current selectedmenu: ${selectedMenu}`);  
    },[selectedMenu]);


    useEffect(() => {
        // Función para detectar orientación de la pantalla
        const handleResize = () => {
            const isLandscapeMode = window.innerWidth > window.innerHeight;
            setIsLandscape(isLandscapeMode);
        };

        // Escuchar cambios en el tamaño de la ventana
        window.addEventListener('resize', handleResize);

        // Detectar orientación inicial
        handleResize();

        // Limpiar el evento al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        // Agrega el estilo al elemento específico después de que el componente se monta
        const style = document.createElement('style');
        style.innerHTML = `
            .ol-attribution-expand {
                display: none !important;
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const openMenuHandle = () => {
        const sidebar = document.querySelector('.sidebar');
        if (sidebar) {
            sidebar.classList.toggle('active');
            setIsMenuActive(sidebar.classList.contains('active'));

            if (isMobile || isIOS) {
                setSelectedMenu(null);
            }
        } else {
            console.warn("Sidebar element not found!");
        }
    }

    const onAnalysisClick = (e) => {
        if (selectedMenu !== "analysis") {
            setSelectedMenu("analysis");
    
            if (!isMobile || !isIOS) {
                const sidebar = document.querySelector('.sidebar');
                if (sidebar) {
                    sidebar.classList.remove('active');
                    setIsMenuActive(false);
                } else {
                    console.warn("Sidebar element not found!");
                }
            }
        } else {
            setSelectedMenu(null);
        }
    }

    const onIotClick = (e) => {
        if (selectedMenu !== "iot") {
            setSelectedMenu("iot");
    
            if (!isMobile || !isIOS) {
                const sidebar = document.querySelector('.sidebar');
                if (sidebar) {
                    sidebar.classList.remove('active');
                    setIsMenuActive(false);
                } else {
                    console.warn("Sidebar element not found!");
                }
            }
        } else {
            setSelectedMenu(null);
        }
    }

    const onContactHandler = () => {
        setShowContact(!showContact);
    }

    const onHelpHandler = () => {
        setShowHelp(!showHelp);
    }

    const onPrivacyHandler = () =>{
        setShowPrivacy(!showPrivacy);
    }

    const onSettingsHandler = () => {
        setShowSettings(!showSettings);
    }

    const logOut = () => {
        setLoggedIn(false);
        localStorage.removeItem('authToken');
        localStorage.removeItem('authTokenExpiration');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        window.location = '/';
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    return (
        
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {!isMobile && (
                <>
                    <ModalDialog
                        dialogTitle="Settings Page"
                        openModal={showSettings}
                        setOpenModal={setShowSettings}
                        onSubmit={onSettingsHandler}
                        cancelButtonText='Close'
                        submitButtonText='Submit'
                        dialogContent={
                            <>
                                <b> Settings Page </b>
                            </>
                        }
                    />
                    <ReportDialog onClickAction={onContactHandler} openDialog={showContact} />
                    <HelpDialog onClickAction={onHelpHandler} openDialog={showHelp} />
                    <PrivacyPolicyModal onClose={onPrivacyHandler} show={showPrivacy}/>

                    <div className="sidebar">
                        <div className="top">
                            <div className="logo">
                                <img src="logo.svg" alt="logo" />
                                <span>Sanzar Group</span>
                            </div>
                            <i className={isMenuActive ? 'bx bx-x' : 'bx bx-menu'} id="btn" onClick={openMenuHandle}></i>
                        </div>
                        <div className="user">
                            <img src="perfil1.png" alt="perfil img" className={`user-img ${isMenuActive && isMobile && isIOS ? "user-img-mobile" : ""}`} />
                            <div>
                                <p className="bold">{userName}</p>
                            </div>
                        </div>
                        <ul>
                            <li title="Digital Maps Projects">
                                <a href="#" onClick={onAnalysisClick}>
                                    <i className="icon-container"><MapIcon /></i>
                                    <span className="nav-item">Digital Maps</span>
                                </a>
                                <span className="tooltip">Analysis</span>
                            </li>
                            <li title="Iot Projects">
                                <a href="#" onClick={onIotClick}>
                                    <i className="icon-container"><FolderIcon /></i>
                                    <span className="nav-item">IoT Projects</span>
                                </a>
                                <span className="tooltip">Analysis</span>
                            </li>
                        </ul>
                        {isMenuActive &&
                            <ul className="menu-down">
                                <li>
                                    <a href="#" onClick={onContactHandler}>
                                        <i className="bx bx-flag"></i>
                                        <span className="nav-item">Contact</span>
                                    </a>
                                    <span className="tooltip">Contact</span>
                                </li>
                                <li>
                                    <a href="#" onClick={onPrivacyHandler}>
                                        <i className="bx bx-shield"></i>
                                        <span className="nav-item">Privacy</span>
                                    </a>
                                    <span className="tooltip">Privacy Policy</span>
                                </li>
                                <li>
                                    <a href="#" onClick={onHelpHandler}>
                                        <i className="bx bx-help-circle"></i>
                                        <span className="nav-item">Help</span>
                                    </a>
                                    <span className="tooltip">Help</span>
                                </li>
                                <li>
                                    <a href="#" onClick={logOut}>
                                        <i className="bx bx-log-out"></i>
                                        <span className="nav-item">Logout</span>
                                    </a>
                                    <span className="tooltip">Logout</span>
                                </li>
                            </ul>}
                    </div>

                    <div className="main-content" style={{ flex: 1 }}>
                        <div className="map" id="map" style={{ width: '100%', height: '100%' }}></div>
                    </div>
                </>
            )}

            {isMobile && (
                 <>
                 <div className={`main-content-mobile ${isLandscape ? 'main-content-mobile-landscape' : ''}`} style={{ flex: 1 }}>

                     <div className="map" id="map" style={{ width: '100%', height: '100%' }}></div>
                 </div>
                 <ReportDialog onClickAction={onContactHandler} openDialog={showContact} />
                 <HelpDialog onClickAction={onHelpHandler} openDialog={showHelp} />
                 <PrivacyPolicyModal onClose={onPrivacyHandler} show={showPrivacy}/>
                 <BottomNavigation 
                    showLabels 
                    className={`bottom-navigation ${isLandscape ? 'bottom-navigation-landscape' : ''}`}>
                     {/* <BottomNavigationAction
                         label={userName}
                         icon={<AccountBoxIcon style={{ color: 'white' }} />}
                         style={{ color: 'white' }}

                     /> */}
                       <BottomNavigationAction
                         label={userName}
                         icon={<AccountBoxIcon icon={faEllipsisV} style={{ color: 'white' }} />}
                         onClick={handleMenuClick}
                         style={{ color: 'white' }}
                     />
                     <BottomNavigationAction
                         label="Digital Maps"
                         icon={<MapIcon style={{ color: 'white' }} />}
                         onClick={onAnalysisClick}
                         style={{ color: 'white' }}
                     />
                     <BottomNavigationAction
                         label="Iot Projects"
                         icon={<FolderIcon style={{ color: 'white' }} />}
                         onClick={onIotClick}
                         style={{ color: 'white' }}
                     />
                   
                     <Menu
                         anchorEl={anchorEl}
                         open={Boolean(anchorEl)}
                         onClose={handleMenuClose}
                     >                        
                         <MenuItem onClick={onHelpHandler}><HelpIcon /> Help</MenuItem>
                         <MenuItem onClick={onPrivacyHandler}><PolicyIcon/>Privacy Policy</MenuItem>
                         <MenuItem onClick={onContactHandler} ><ForwardToInboxIcon  /> Contact</MenuItem>
                         <MenuItem onClick={logOut} ><ExitToAppIcon  /> Logout</MenuItem>
                     </Menu>
                 </BottomNavigation>
             </>
            )}
        </div>
    );
}

export default Navbar;