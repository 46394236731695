import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { MapContext } from '../../context/MapContextProvider';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Fill, Stroke, Circle as CircleStyle } from 'ol/style';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import Draw from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import { Tooltip, Button, Dialog, DialogTitle, DialogContent, List, ListItem, IconButton, DialogActions } from '@mui/material';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import proj4 from 'proj4';
import PhotoCaptureComponent from './PhotoCaptureComponent';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import imageCompression from 'browser-image-compression';

const NamePointComponent = ({ userMail }) => {
    const { map } = useContext(MapContext);
    const [pointName, setPointName] = useState('');
    const vectorSourceRef = useRef(new VectorSource());
    //const [currentFeature, setCurrentFeature] = useState(null);
    const currentFeatureRef = useRef(null);
    const overlayRef = useRef(null);
    const drawRef = useRef(null);
    const [drawActive, setDrawActive] = useState(false);
    const [savedPoints, setSavedPoints] = useState([]);
    const [open, setOpen] = useState(false); // Estado para manejar el popup
    const [photoOpen, setPhotoOpen] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogPhoto, setDialogPhoto] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;

    useEffect(() => {
        const cameraContainer = document.createElement('div');
        cameraContainer.id = 'camera-container';
        document.body.appendChild(cameraContainer);
    
        return () => {
            document.body.removeChild(cameraContainer);
        };
    }, []);

    useEffect(() => {
        const vectorLayer = new VectorLayer({
            source: vectorSourceRef.current,
            properties: {
                displayInLayerSwitcher: false          
            },
            style: new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({ color: 'red' }),
                    stroke: new Stroke({ color: 'red', width: 1 }) 
                })
            }),
        });

        map.addLayer(vectorLayer);

        // Mover la capa al final de la lista de capas
        map.getLayers().remove(vectorLayer);
        map.getLayers().push(vectorLayer);

        overlayRef.current = new Overlay({
            element: document.getElementById('name-point-form'),
            positioning: 'bottom-center',
            stopEvent: true,
            offset: [0, -15]
        });

        map.addOverlay(overlayRef.current);

        drawRef.current = new Draw({
            source: vectorSourceRef.current,
            type: 'Point'
        });

        drawRef.current.on('drawstart', () => {

            // Eliminar el punto provisional anterior si existe
            if (currentFeatureRef.current) {
                vectorSourceRef.current.removeFeature(currentFeatureRef.current);
                currentFeatureRef.current = null;
            }
        });

        drawRef.current.on('drawend', (event) => {
            if (currentFeatureRef.current) {
                vectorSourceRef.current.removeFeature(currentFeatureRef.current);
            }
            currentFeatureRef.current = event.feature;
            const coordinates = event.feature.getGeometry().getCoordinates();
            overlayRef.current.setPosition(coordinates);
            document.getElementById('name-point-form').style.display = 'block';
            toggleDraw(); //Esto desactiva la interacción del dibujo después de agregar el punto. 
        });

       // map.addInteraction(drawRef.current);

        return () => {
            map.removeLayer(vectorLayer);
            map.removeOverlay(overlayRef.current);
            if (drawActive) {
                map.removeInteraction(drawRef.current);
            }
        };
    }, [map]);

    const handleMapClick = (event) => {
        const features = map.getFeaturesAtPixel(event.pixel);
        if (features.length > 0) {
            const clickedFeature = features[0];
            const photo = clickedFeature.get('photo');
            
            if (photo) {
                setDialogPhoto(photo);
                setDialogOpen(true);
            }
        }
    };  

    const toggleDraw = () => {
        if (drawActive) {
            map.removeInteraction(drawRef.current);
        } else {
            map.addInteraction(drawRef.current);
        }
        setDrawActive(!drawActive);
    };

    const handleSave = async () => {

        if (!pointName.trim()) {
            alert('El nombre del punto no puede estar vacío. Por favor, ingresa un nombre.');
            return; // Salir de la función sin guardar el punto
        }
        if (!currentPhoto) {  // Validación de la foto
            alert('Debe añadir una foto antes de guardar el punto.');
            return;
        }
        if (savedPoints && savedPoints.length >= 20) {
            alert('Has alcanzado el número máximo de 20 puntos.');
            return;
        }
        
        if (currentFeatureRef.current) {
            const coordinates = currentFeatureRef.current.getGeometry().getCoordinates();
            const converted = proj4('EPSG:3857', 'EPSG:4326', coordinates); // Convertir las coordenadas a EPSG:4326

            currentFeatureRef.current.set('name', pointName);
            currentFeatureRef.current.set('coordinates', converted);


            if (currentPhoto && !null) {
                const compressedPhoto = await compressImage(currentPhoto);
                if (compressedPhoto) {
                    currentFeatureRef.current.set('photo', compressedPhoto);
                }
            }

             // Verifica si el elemento 'name-point-form' existe antes de acceder a él
            const namePointFormElement = document.getElementById('name-point-form');
            if (namePointFormElement) {
                const nameOverlay = document.createElement('div');
                nameOverlay.innerHTML = `${pointName} `; // Mostrar las coordenadas convertidas

                // Añadir estilos para el recuadro blanco transparente
                nameOverlay.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
                nameOverlay.style.padding = '5px';
                nameOverlay.style.borderRadius = '5px';
                nameOverlay.style.border = '1px solid black';
                nameOverlay.style.cursor = 'pointer'; //Esto hace al texto clicable. 

                const feature = currentFeatureRef.current;
                const name = feature.get('name');

                nameOverlay.addEventListener('click', () => {
                    const photo = feature.get('photo');
                    if (photo) {
                        setDialogPhoto(photo);
                        setPointName(name);
                        setDialogOpen(true);
                    }
                });

                const overlay = new Overlay({
                    element: nameOverlay,
                    positioning: 'bottom-center',
                    stopEvent: false,
                    offset: [0, -15],
                    position: coordinates
                });
                map.addOverlay(overlay);

                const newPoint = {
                    feature: currentFeatureRef.current,
                    overlay,
                    name: pointName,
                    coordinates: converted,
                    photo: currentFeatureRef.current.get('photo') || null // Guardar la foto con el punto
                };
                setSavedPoints([...savedPoints, newPoint]);

                //document.getElementById('name-point-form').style.display = 'none';
                namePointFormElement.style.display = 'none';
            }
            setPointName('');
            setCurrentPhoto(null); // Resetear la foto después de guardar
            currentFeatureRef.current = null;
            toggleDraw();
        }
    };

    const compressImage = async (imageDataUrl) => {

        if (!imageDataUrl) {
            console.error("La imagen es nula o indefinida");
            return null;
        }

        const imageFile = dataURLtoFile(imageDataUrl, 'photo.jpg');

        if (!imageFile) {
            console.error("No se pudo convertir la dataURL en un archivo");
            return imageDataUrl; // Devuelve la dataURL original si la conversión falla
        }

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(compressedFile);
            });
        } catch (error) {
            console.error('Error compressing image:', error);
            return imageDataUrl;
        }
    };

    const handleDelete = (featureToDelete = null) => {
        const feature = featureToDelete || currentFeatureRef.current;
        if (feature) {
            vectorSourceRef.current.removeFeature(feature);

             // Encontrar el punto guardado correspondiente
            const pointToDelete = savedPoints.find(point => point.feature === feature);

            if (pointToDelete) {
                // Eliminar el overlay asociado
                map.removeOverlay(pointToDelete.overlay);
            }


            const updatedSavedPoints = savedPoints.filter(point => point.feature !== feature);
            setSavedPoints(updatedSavedPoints);

            if (featureToDelete === null) {
                document.getElementById('name-point-form').style.display = 'none';
                setPointName('');
                setCurrentPhoto(null); // Resetear la foto después de eliminar
                currentFeatureRef.current = null;
            }
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePhotoOpen = () => {
        setPhotoOpen(true);
    };

    const handlePhotoClose = () => {
        setPhotoOpen(false);
    };

    const handlePhotoCapture = (photo) => {
        setCurrentPhoto(photo);
        handlePhotoClose();
    };
    const handleTakePhoto = (dataUri) => {
        setCurrentPhoto(dataUri);
        setPhotoOpen(false);
        // Aquí puedes manejar la foto tomada, como guardarla en un estado, subirla a un servidor, etc.
    };

    const handleSendPoints = async () => {
        const formData = new FormData();

         // Agrega el correo del usuario al formulario
        formData.append('email', userMail);

        savedPoints.forEach((point, index) => {
            formData.append(`points[${index}].name`, point.name);
            formData.append(`points[${index}].coordinates.latitude`, point.coordinates[1]);
            formData.append(`points[${index}].coordinates.longitude`, point.coordinates[0]);

            if (point.photo) {
                const compressedPhoto = dataURLtoFile(point.photo, `image_${index + 1}.jpg`);
                formData.append(`points[${index}].photo`, compressedPhoto);
            }
        });

        try {
            const response = await fetch(`${API_BASE_URL}/api/SendPoints/send-email`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                alert('Points sent successfully');
            } else {
                alert('Failed to send points');
            }
        } catch (error) {
            console.error('Error sending points:', error);
            alert('Error sending points');
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        if (!dataurl) {
            console.error("dataURL es nulo o indefinido");
            return null;
        }

        const arr = dataurl.split(',');
        if (arr.length < 2) {
            console.error("dataURL no está en el formato esperado");
            return null;
        }

        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setDialogPhoto(null);
    };


    return (
        <div className='tools'>
            <Tooltip title="Add Point" arrow>
                <Button onClick={toggleDraw} className='add-point-button'>
                    <AddLocationIcon style={{ color: drawActive ? '#c66e69' : 'white' }}/>
                </Button>
            </Tooltip>
            <Tooltip title="View Points" arrow>
                <Button onClick={handleClickOpen} className='view-points-button'>
                    <ListIcon style={{ color: 'white' }}/>
                </Button>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Saved Points</DialogTitle>
                <DialogContent>
                    <List>
                        {savedPoints.map((point, index) => (
                            <ListItem key={index}>
                                {point.name} ({point.coordinates[1].toFixed(6)}, {point.coordinates[0].toFixed(6)})
                                {point.photo && <img src={point.photo} alt={point.name} style={{ width: '50px', height: '50px' }} />}
                                <IconButton onClick={() => handleDelete(point.feature)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => handleSendPoints()} color="primary" variant="contained">
                        Send Points
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Photo: {pointName}</DialogTitle>
                    <DialogContent>
                        {dialogPhoto && <img src={dialogPhoto} alt="Punto guardado" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <div id="name-point-form" style={{
                display: 'none',
                position: 'absolute',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid black',
                zIndex: 1000
            }}>
                <label htmlFor="point-name">Nombre del Punto:</label>
                <input 
                    type="text" 
                    id="point-name" 
                    value={pointName} 
                    onChange={(e) => setPointName(e.target.value)} 
                    style={{ display: 'block', marginBottom: '10px' }}
                />
                <IconButton onClick={() => handleSave()} color="primary" style={{ marginRight: '10px' }}>
                    <SaveIcon />
                </IconButton>
                <IconButton onClick={() => handlePhotoOpen()} color="primary" style={{ marginRight: '10px' }}>
                    <AddAPhotoIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete()} color="error" style={{ marginRight: '10px' }}>
                    <DeleteIcon />
                </IconButton>
            </div>
            {photoOpen && ReactDOM.createPortal(
                <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 9999,
                  backgroundColor: 'rgba(0, 0, 0, 1)', 
                  opacity: 1,
                  display: photoOpen ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Camera
                  onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                  idealFacingMode="environment"
                   isFullscreen={true}
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 1)', // Fondo sólido negro
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    zIndex: 10000, // Asegúrate de que esté por encima de otros elementos
                  }}
                />
              </div>,
              document.getElementById('camera-container')
            )}

            {/* {currentPhoto && (
                <div
                    style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 9999,
                    backgroundColor: 'black',
                    display: currentPhoto ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <img src={currentPhoto} alt="Captura" />
                </div>
            )} */}
        </div>
    );
};

export default NamePointComponent;
