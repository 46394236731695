import { useContext, useEffect, useState, useRef } from "react";
import { MapContext } from "../../context/MapContextProvider";
import { Button } from '@mui/material';
import { Tooltip } from '@mui/material';
import { PinDrop } from "@mui/icons-material";
import proj4 from "proj4";
import { useAlert } from "../Alert/useAlert";
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { Point, Polygon as OlPolygon } from 'ol/geom';
import Feature from 'ol/Feature';
import { openDB } from 'idb';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Overlay from 'ol/Overlay';
import { isMobile } from "react-device-detect";


const GeoLocateComponent = () => {
    const { map } = useContext(MapContext);
    const showAlert = useAlert();
    const watchId = useRef(null);
    const [alertShown, setAlertShown] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState("");
    const successfullyLocated = useRef(false);
    const [coords, setCoords] = useState('');
    const [heading, setHeading] = useState(null);
    const vectorSourceRef = useRef(new VectorSource());
    const userFeatureRef = useRef(new Feature());
    const [orientationSupported, setOrientationSupported] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const beamFeatureRef = useRef(new Feature());
    const [isFirstCentering, setIsFirstCentering] = useState(true);



    useEffect(() => {
        const vectorLayer = new VectorLayer({
            source: vectorSourceRef.current,
            properties: {
                displayInLayerSwitcher: false
            }
        });
        map.addLayer(vectorLayer);
    
        const handleOrientation = (event) => {
            if (event.alpha !== null) {
                setHeading(event.alpha);
                setOrientationSupported(true);
            } else {
                setOrientationSupported(false);
            }
        };
    
        if (window.DeviceOrientationEvent) {
            if (typeof DeviceOrientationEvent.requestPermission === 'function') {
                DeviceOrientationEvent.requestPermission()
                    .then(permissionState => {
                        if (permissionState === 'granted') {
                            window.addEventListener('deviceorientation', handleOrientation, true);
                        } else {
                            setOrientationSupported(false);
                        }
                    })
                    .catch(() => setOrientationSupported(false));
            } else {
                window.addEventListener('deviceorientation', handleOrientation, true);
                setOrientationSupported(true);
            }
        } else {
            setOrientationSupported(false);
        }
    
        return () => {
            map.removeLayer(vectorLayer);
            if (watchId.current !== null) {
                navigator.geolocation.clearWatch(watchId.current);
            }
            window.removeEventListener('deviceorientation', handleOrientation, true);
        };
    }, [map]);
    

    useEffect(() => {
        if (coords) {
            updateUserLocation(coords);
        }
    }, [coords, heading, orientationSupported, map, showAlert]);

    const ANGLE_OFFSET = 90; 
    const updateUserLocation = (coords) => {
        const converted = proj4("EPSG:4326", "EPSG:3857", coords);
        userFeatureRef.current.setGeometry(new Point(converted));
    
        if (heading !== null) {
            const beamLength = 50; // Longitud del haz de luz en metros
            const correctedHeading = (heading + ANGLE_OFFSET) % 360; // Ajuste del ángulo
            const angleRad = correctedHeading * Math.PI / 180; // Convertir a radianes
            const beamCoords = [
                converted,
                [
                    converted[0] + beamLength * Math.cos(angleRad - Math.PI / 12),
                    converted[1] + beamLength * Math.sin(angleRad - Math.PI / 12)
                ],
                [
                    converted[0] + beamLength * Math.cos(angleRad + Math.PI / 12),
                    converted[1] + beamLength * Math.sin(angleRad + Math.PI / 12)
                ],
                converted
            ];
    
            const beamPolygon = new OlPolygon([beamCoords]);
            beamFeatureRef.current.setGeometry(beamPolygon);
    
            if (!vectorSourceRef.current.getFeatures().includes(beamFeatureRef.current)) {
                vectorSourceRef.current.addFeature(beamFeatureRef.current);
            }
        }
    
        userFeatureRef.current.setStyle(getUserLocationStyle(heading, orientationSupported)[0]);
        beamFeatureRef.current.setStyle(getUserLocationStyle(heading, orientationSupported)[1]);
    
        if (!vectorSourceRef.current.getFeatures().includes(userFeatureRef.current)) {
            vectorSourceRef.current.addFeature(userFeatureRef.current);
        }
    };

    const getUserLocationStyle = (heading, orientationSupported) => {
        // Definir el estilo del círculo azul
        const userLocationStyle = new Style({
            image: new Circle({
                radius: 8,
                fill: new Fill({
                    color: 'rgba(0, 0, 255, 0.6)' // Azul semitransparente
                }),
                stroke: new Stroke({
                    color: 'blue',
                    width: 2
                })
            })
        });
    
        // Definir el estilo del "haz de luz"
        const lightBeamStyle = new Style({
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.3)' // Azul muy semitransparente
            })
        });
        //console.log("userLocationStyle", userLocationStyle);
        //console.log("lightBeamStyle", lightBeamStyle);

        return [userLocationStyle, lightBeamStyle];
    };
    

    const centerMapOnUser = (coords) => {
        const converted = proj4("EPSG:4326", "EPSG:3857", coords);
        map.getView().setCenter(converted);
        map.getView().setZoom(18);
        map.updateSize(); // Forzar actualización visual del mapa
        // showAlert("Map GeoLocated to the Position");
        successfullyLocated.current = true;
        cacheTilesForLocation(coords[0], coords[1], 15);
    };

    const getLocation = () => {
        if (!navigator.geolocation) {
            showAlert('Geolocation is not supported by this browser.', 'error');
            return;
        }

        resetCenterUserLocation();

        if (watchId.current === null) {
            watchId.current = navigator.geolocation.watchPosition(showPosition, errorFunction, {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 5000
            });
        }
    };

    const toggleGeoLocation = () => {
        if (isActive) {
            if (watchId.current !== null) {
                navigator.geolocation.clearWatch(watchId.current);
                watchId.current = null;
            }
            setIsActive(false);
        } else {
            getLocation();
            setIsActive(true);
        }
    };

    function errorFunction(e) {
        if (!successfullyLocated.current) {
            showAlert("Message: " + e?.message, 'error');
        }
    }

    function showPosition(position) {
        const newcoords = [position.coords.longitude, position.coords.latitude];
        setCoords(newcoords);

        if (isFirstCentering) {
            centerMapOnUser(newcoords);
            setIsFirstCentering(false);
        }
    }

    const resetCenterUserLocation = () => {
        successfullyLocated.current = false;
    }

    const cacheTilesForLocation = async (longitude, latitude, zoom) => {
        const urls = getTileUrlsForLocation(longitude, latitude, zoom);
        const db = await openDB('bing-positionMap-tiles', 1, {
            upgrade(db) {
                db.createObjectStore('tiles');
            },
        });
        let { quota, usage } = await navigator.storage.estimate();
        for (const url of urls) {
            const response = await fetch(url);
            const blob = await response.blob();
            const contentLength = blob.size;
            if (usage + contentLength > quota) {
                console.log('Storage quota exceeded, not caching:', url);
            } else {
                await db.put('tiles', blob, url);
                usage += contentLength;
            }
        }
    };

    const getTileUrlsForLocation = (longitude, latitude, zoom) => {
        const x = Math.floor((longitude + 180) / 360 * Math.pow(2, zoom));
        const y = Math.floor((1 - Math.log(Math.tan(latitude * Math.PI / 180) + 1 / Math.cos(latitude * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom));
        const urls = [];
        for (let dx = -1; dx <= 1; dx++) {
            for (let dy = -1; dy <= 1; dy++) {
                const quadKey = tileXYToQuadKey(x + dx, y + dy, zoom);
                const url = `https://ecn.t${quadKey[quadKey.length - 1]}.tiles.virtualearth.net/tiles/a${quadKey}.jpeg?g=14519`;
                urls.push(url);
            }
        }
        return urls;
    };

    const tileXYToQuadKey = (tileX, tileY, zoomLevel) => {
        let quadKey = '';
        for (let i = zoomLevel; i > 0; i--) {
            let digit = '0';
            const mask = 1 << (i - 1);
            if ((tileX & mask) !== 0) {
                digit++;
            }
            if ((tileY & mask) !== 0) {
                digit++;
                digit++;
            }
            quadKey += digit;
        }
        return quadKey;
    };

    //useEffect(() => {
    //    console.log(successfullyLocated);
    //}, [successfullyLocated]);

    return (
        <div className="tools">
            {/* <Tooltip title="Geo Location" arrow>
                <RadioButtonCheckedIcon 
                    onClick={toggleGeoLocation} 
                    style={{ color: isActive ? '#c66e69'
                          : (isMobile ? 'black' : 'white')}}
                />
            </Tooltip> */}
            <Button 
            title="Geo Location"
            onClick={toggleGeoLocation} 
            style={{ color: isActive ? '#c66e69'
                : (isMobile ? 'black' : 'white')}}
            >
                <RadioButtonCheckedIcon/>
            </Button>
        </div>
    );
}

export default GeoLocateComponent;
