import { useContext, useEffect, useState } from "react";
import { MapContext } from "../../context/MapContextProvider";
import './DrawingTool.scss';
import {
//    Divider,
 //   IconButton,
 Tooltip,
 IconButton,
 Menu,
 MenuItem
} from '@mui/material';
import HandymanIcon from '@mui/icons-material/Handyman';
//import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { DeleteOutline, HighlightAlt, 
   // ModeEditOutlineOutlined, PolylineOutlined, SaveOutlined, 
    StraightenOutlined } from "@mui/icons-material";
import {
    Circle as CircleStyle,
    Fill,
    RegularShape,
    Stroke,
    Style,
    Text,
} from 'ol/style.js';

import MultiPoint from 'ol/geom/MultiPoint.js';
import { Draw, Modify } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { getArea, getLength } from 'ol/sphere.js';
import { LineString, Point } from 'ol/geom.js';
import { AppContext } from "../../context/AppContextProvider";
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';

// const editStyles = [
//     /* We are using two different styles for the polygons:
//      *  - The first style is for the polygons themselves.
//      *  - The second style is to draw the vertices of the polygons.
//      *    In a custom `geometry` function the vertices of a polygon are
//      *    returned as `MultiPoint` geometry, which will be used to render
//      *    the style.
//      */
//     new Style({
//         stroke: new Stroke({
//             color: 'blue',
//             width: 3,
//         }),
//         fill: new Fill({
//             color: 'rgba(0, 0, 255, 0.1)',
//         }),
//     }),
//     new Style({
//         image: new CircleStyle({
//             radius: 5,
//             fill: new Fill({
//                 color: 'orange',
//             }),
//         }),
//         geometry: function (feature) {
//             // return the coordinates of the first ring of the polygon
//             const coordinates = feature.getGeometry().getCoordinates()[0];
//             return new MultiPoint(coordinates);
//         },
//     }),
// ];

const style = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2,
    }),
    image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
    }),
});

const labelStyle = new Style({
    text: new Text({
        font: '14px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        padding: [3, 3, 3, 3],
        textBaseline: 'bottom',
        offsetY: -15,
    }),
    image: new RegularShape({
        radius: 8,
        points: 3,
        angle: Math.PI,
        displacement: [0, 10],
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
    }),
});

const tipStyle = new Style({
    text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        padding: [2, 2, 2, 2],
        textAlign: 'left',
        offsetX: 15,
    }),
});

const modifyStyle = new Style({
    image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
    }),
    text: new Text({
        text: 'Drag to modify',
        font: '12px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        padding: [2, 2, 2, 2],
        textAlign: 'left',
        offsetX: 15,
    }),
});

const segmentStyle = new Style({
    text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        padding: [2, 2, 2, 2],
        textBaseline: 'bottom',
        offsetY: -12,
    }),
    image: new RegularShape({
        radius: 6,
        points: 3,
        angle: Math.PI,
        displacement: [0, 8],
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
    }),
});

const formatLength = function (line) {
    const length = getLength(line);
    let output;
    if (length > 1000) {
        // output = Math.round((length / 1000) * 100) / 100 + ' km';
        output = (((length / 1000) * 100) / 100).toFixed(4) + ' km';;

    } else {
        // output = Math.round(length * 100) / 100 + ' m';
        output = ((length * 100) / 100).toFixed(4) + ' m';
    }
    return output;
};

const formatArea = function (polygon) {
    const area = getArea(polygon);
    let km = (((area / 1000000) * 100) / 100).toFixed(3);
    let output;
    if (area > 10000) {
        output = km + ' km\xB2';
    } else {
        let meter = ((area * 100) / 100).toFixed(3)
        output = + meter + ' m\xB2';
    }
    return output;
};
//const kmPrice = 10;
const segmentStyles = [segmentStyle];
var tipPoint, modify;

const AnalysisComponent = () => {

    const { map } = useContext(MapContext);
    const { selectedMenu } = useContext(AppContext);
    const [drawSource, setDrawSource] = useState();
    const [activeDrawingType, setActiveDrawingType] = useState("");
    const [drawAction, setDrawAction] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
const open = Boolean(anchorEl);


    useEffect(() => {
        //Load KML from local stroage
        const source = new VectorSource();

        const vector = new VectorLayer({
            source: source,
            style: function (feature) {
                //todo show / hide segments here
                return styleFunction(feature, true);
            },
            zIndex: 1000,
            title: "Measurement"
        });

        modify = new Modify({ source: source, style: modifyStyle });
        setDrawSource(source);
        map.addLayer(vector)
        map.addInteraction(modify);

        return () => {
            cleanupMap();
        };

    }, [map, selectedMenu]);

    const cleanupMap = () => {
        if (map) {
            map.getInteractions().forEach(interaction => {
                if (interaction instanceof Draw || interaction instanceof Modify) {
                    map.removeInteraction(interaction);
                }
            });
    
            // Además, elimina cualquier capa específica que no deba persistir.
            map.getLayers().getArray()
                .filter(layer => layer.get('title') === 'Measurement')
                .forEach(layer => map.removeLayer(layer));
    
            if (drawSource) {
                drawSource.clear();
            }
        }
    };

    function addInteraction(source, drawType = "Polygon") {
        // Si ya hay una herramienta activa del mismo tipo, desactivarla
        if (drawAction && activeDrawingType === drawType) {
            map.removeInteraction(drawAction);
            setDrawAction(null);
            setActiveDrawingType("");
            return; // Termina la función aquí para no volver a añadir la misma interacción.
        }
         // Si hay otra herramienta activa, eliminarla
        if (drawAction) {
            map.removeInteraction(drawAction);
        }

        // if (activeDrawingType == drawType)
        //     setActiveDrawingType("");
        // else
        //     setActiveDrawingType(drawType);

        setActiveDrawingType(drawType);

        const activeTip =
            'Click to continue drawing the ' +
            (drawType === 'Polygon' ? 'polygon' : 'line');

        const idleTip = 'Click to start measuring';

        let tip = idleTip;
        let draw = new Draw({
            source: source,
            type: drawType,
            style: function (feature) {
                return styleFunction(feature, true, drawType, tip);
            },
        });

        draw.on('drawstart', function () {
            modify.setActive(false);
            tip = activeTip;
        });

        draw.on('drawend', function () {
            modifyStyle.setGeometry(tipPoint);
            modify.setActive(true);
            map.once('pointermove', function () {
                modifyStyle.setGeometry();
            });
            tip = idleTip;
            // Eliminar la interacción de dibujo y restablecer el estado
            map.removeInteraction(draw);
            setDrawAction(null);
            setActiveDrawingType("");
        });
        setDrawAction(draw);
        map.addInteraction(draw);
    }


    function styleFunction(feature, segments, drawType, tip) {
        const styles = [];
        const geometry = feature.getGeometry();
        const type = geometry.getType();
        let point, label, line;
        if (!drawType || drawType === type || type === 'Point') {
            styles.push(style);
            if (type === 'Polygon') {
                point = geometry.getInteriorPoint();
                label = formatArea(geometry);
                line = new LineString(geometry.getCoordinates()[0]);
            } else if (type === 'LineString') {
                point = new Point(geometry.getLastCoordinate());
                label = formatLength(geometry);
                line = geometry;
            }
        }
        if (segments && line) {
            let count = 0;
            line.forEachSegment(function (a, b) {
                const segment = new LineString([a, b]);
                const label = formatLength(segment);
                if (segmentStyles.length - 1 < count) {
                    segmentStyles.push(segmentStyle.clone());
                }
                const segmentPoint = new Point(segment.getCoordinateAt(0.5));
                segmentStyles[count].setGeometry(segmentPoint);
                segmentStyles[count].getText().setText(label);
                styles.push(segmentStyles[count]);
                count++;
            });
        }
        if (label) {
            labelStyle.setGeometry(point);
            labelStyle.getText().setText(label);
            styles.push(labelStyle);
        }
        if (
            tip &&
            type === 'Point' &&
            !modify.getOverlay().getSource().getFeatures().length
        ) {
            tipPoint = geometry;
            tipStyle.getText().setText(tip);
            styles.push(tipStyle);
        }
        return styles;
    }

    const drawProjectArea = (type) => {
        addInteraction(drawSource, type);
    }
    
    const clearDrawing = () => {
        // Check if drawSource is initialized
        try {
            if (drawSource) {
                drawSource.clear(); // Clears all features from the vector source
                console.log("All drawings have been cleared from the map.");
            }
        } catch (e) {
            console.erro("An error occurred while trying to clear drawings:", e);
        }
      
    };

    return (
        <div className="tools" title="Measure tools">
            <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
        >
            <DesignServicesOutlinedIcon sx={{ color: "#fff" }}/>
            </IconButton >
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => { drawProjectArea("Polygon"); setAnchorEl(null); }}>
                    <Tooltip title="Measure Area" arrow>
                        <HighlightAlt sx={{ color: activeDrawingType === "Polygon" ? "#e57373" : "#000" }} />
                    </Tooltip>
                </MenuItem>
                <MenuItem onClick={() => { drawProjectArea("LineString"); setAnchorEl(null); }}>
                    <Tooltip title="Measure Line" arrow>
                        <StraightenOutlined sx={{ color: activeDrawingType === "LineString" ? "#e57373" : "#000" }} />
                    </Tooltip>
                </MenuItem>
                <MenuItem onClick={() => { clearDrawing(); setAnchorEl(null); }}>
                    <Tooltip title="Delete Polygons" arrow>
                        <DeleteOutline sx={{ color: "#000" }} />
                    </Tooltip>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default AnalysisComponent;