import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import * as d3 from "d3";
import { AppContext } from "../../context/AppContextProvider";
import { GridLoader } from "react-spinners";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import "./MapTools.scss";

const LineChartWithLabelsComponentcopy = ({ open, onClose }) => {
    //const [open, setOpen] = useState(false);
    const [apiData, setApiData] = useState([]); // Estado para los datos del API
    const [loading, setLoading] = useState(false); // Estado para el cargando
    const {dataSensor} = useContext(AppContext);
    const [activeLines, setActiveLines] = useState({});
    
    // const handleParentChange = (event) => {
    //     const isChecked = event.target.checked;
    //     setChecked({ parent: isChecked, child1: isChecked, child2: isChecked });
    // };
    // const handleChildChange = (child) => (event) => {
    //     const isChecked = event.target.checked;
    //     setChecked((prev) => {
    //         const newState = { ...prev, [child]: isChecked };
    //         newState.parent = newState.child1 && newState.child2;
    //         return newState;
    //     });
    // };
   useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open]);

    // Función para llamar a la API
    const fetchData = async () => {
        if (!dataSensor) {
            console.error("Error: 'dataSensor' is not defined or is empty.");
            return;
        }
        try {
            setLoading(true);
            const API_SENSOR_BASE_URL = process.env.REACT_APP_API_SENSOR_URL;
            //console.log(`datasensor from request: ${dataSensor}`);
            const response = await axios.get(`${API_SENSOR_BASE_URL}tableName=${dataSensor}&limit=80`);
            const formattedData = response.data.map((item) => ({
                measure_name: item.measure_name,
                time: item.time,
                measure_value: Number(item["measure_value::double"] ?? item["measure_value::bigint"]),
                
            }));
            // Configura `activeLines` dinámicamente según las medidas devueltas
            const measures = [...new Set(formattedData.map((d) => d.measure_name))].filter(
                (measure) => measure !== "battery"
            );
            const activeLinesState = measures.reduce((acc, measure) => {
                acc[measure] = true; // Inicializa todas las líneas como activas
                return acc;
            }, {});
            setActiveLines(activeLinesState);
            setApiData(formattedData); // Guardar los datos procesados
            //console.log(formattedData);
        } catch (error) {
            console.error("Error fetching data from API:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open && apiData.length > 0) {
            drawChart(); // Dibuja la gráfica cuando los datos están disponibles
        }
    }, [open, apiData, activeLines]);

    const handleCheckboxChange = (lineName) => {
        setActiveLines((prev) => ({
            ...prev,
            [lineName]: !prev[lineName],
        }));
    };

    const drawChart = () => {
        d3.select("#line-chart").selectAll("*").remove();
    
        const margin = { top: 20, right: 100, bottom: 30, left: 50 };
        const width = 720 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
    
        const svg = d3
            .select("#line-chart")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
    
        const filteredData = apiData
            .filter((d) => d.measure_name !== "battery" && activeLines[d.measure_name])
            .sort((a, b) => new Date(a.time) - new Date(b.time));
        const groupedData = d3.groups(filteredData, (d) => d.measure_name);
    
        const xScale = d3
            .scaleTime()
            .domain(d3.extent(filteredData, (d) => new Date(d.time)))
            .range([0, width]);
    
        const yScale = d3
            .scaleLinear()
            .domain([0, d3.max(filteredData, (d) => d.measure_value)])
            .nice()
            .range([height, 0]);
    
        const colorScale = d3
            .scaleOrdinal()
            .domain(groupedData.map(([key]) => key))
            .range(d3.schemeCategory10);
    
        // Añadir las cuadrículas del eje Y
        // svg.append("g")
        //     .attr("class", "y-gridlines")
        //     .call(
        //         d3.axisLeft(yScale)
        //             .tickSize(-width) // Mantener las líneas de cuadrícula
        //             .tickFormat("") // Ocultar etiquetas del eje Y
        //     )
        //     .selectAll("line")
        //     .attr("stroke", "#e0e0e0"); // Color de las líneas de cuadrícula
    
        // Eliminar cualquier resto del eje Y principal
        // svg.select(".y-gridlines .domain").remove();
    
        // Añadir el eje X
        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale).ticks(5));
    
        // const line = d3
        //     .line()
        //     .x((d) => xScale(new Date(d.time)))
        //     .y((d) => yScale(d.measure_value))
        //     .curve(d3.curveCatmullRom);
    
        groupedData.forEach(([key, values]) => {
            const line = d3
            .line()
            .x((d) => xScale(new Date(d.time)))
            .y((d) => yScale(d.measure_value))
            .curve(d3.curveCatmullRom);

            const path = svg
                .append("path")
                .datum(values)
                .attr("fill", "none")
                .attr("stroke", colorScale(key))
                .attr("stroke-width", 2)
                .attr("d", line)
                .attr("stroke-dasharray", function () {
                    const totalLength = this.getTotalLength();
                    return `${totalLength},${totalLength}`;
                })
                .attr("stroke-dashoffset", function () {
                    return this.getTotalLength();
                })
                .transition()
                .duration(2000)
                .ease(d3.easeLinear)
                .attr("stroke-dashoffset", 0);

            // Añadir puntos
            svg.selectAll(".dot-" + key)
                .data(values)
                .enter()
                .append("circle")
                .attr("class", "dot-" + key)
                .attr("cx", (d) => xScale(new Date(d.time)))
                .attr("cy", (d) => yScale(d.measure_value))
                .attr("r", 3)
                .attr("fill", colorScale(key));
    
            // Añadir etiquetas de texto
            svg.selectAll(".label-" + key)
                .data(values)
                .enter()
                .append("text")
                .attr("x", (d) => xScale(new Date(d.time)))
                .attr("y", (d) => yScale(d.measure_value) - 10)
                .attr("text-anchor", "middle")
                .style("font-size", "10px")
                .text((d) => d.measure_value);
    
            // Agregar texto al final de la línea
            const lastPoint = values[values.length - 1];
            svg.append("text")
                .attr("x", xScale(new Date(lastPoint.time)) + 10)
                .attr("y", yScale(lastPoint.measure_value))
                .attr("dy", "0.35em")
                .style("font-size", "13px")
                .style("font-weight", "bold")
                .style("fill", colorScale(key))
                .text(key);
        });
    };

    
    return (

        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className="custom-dialog ">
            <DialogTitle>Line Chart with Labels</DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <GridLoader size={30} color="#2881a7" />
                    </div>
                ) : (
                    <div id="line-chart"></div>
                )}
                <Box sx={{ mt: 2 }}>
                    {Object.keys(activeLines).map((lineName) => (
                    <FormControlLabel
                        key={lineName}
                        label={lineName}
                        control={
                        <Checkbox
                            checked={activeLines[lineName]}
                            onChange={() => handleCheckboxChange(lineName)}
                        />
                        }
                    />
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end", // Alinea elementos al final (a la derecha)
                        mt: 2, // Margen superior para separación
                    }}
                >     
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>                 
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default LineChartWithLabelsComponentcopy;
