import React, { useEffect, useState, useContext, useRef } from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Button } from "@mui/material";
import Overlay from "ol/Overlay";
import Switch from '@mui/material/Switch';
import { MapContext } from "../../context/MapContextProvider";
import { AppContext } from "../../context/AppContextProvider";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Style, Icon } from "ol/style";
import {Circle as CircleStyle, Stroke} from 'ol/style.js';
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import { Point } from "ol/geom";
import Feature from "ol/Feature";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import sensorIcon from '../../assets/sensors_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg';
import { fromLonLat } from "ol/proj";
import './IotMenuComponent.scss';
import DataTableComponentcopy from "../MapTools/DataTableComponentcopy";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import LineChartWithLabelsComponentcopy from "../MapTools/LineChartWithLabelsComponentcopy";


const IotMenuComponent = () => {
    const { userId, setUserId,selectedMenu, setSelectedMenu,isMenuActive } = useContext(AppContext);
    const { map, addLayer } = useContext(MapContext);
    const [projectsIot, setProjectsIot] = useState([]);
    const vectorSourceRef = useRef(new VectorSource());
    const vectorLayerRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalLineChart, setOpenModalLineChart] = useState(false);
    const overlayRef = useRef(); 
    const [sensorVisibility, setSensorVisibility] = useState({});
    const [overlayVisible, setOverlayVisible] = useState(false); // Controla la visibilidad del overlay
    const [overlayPosition, setOverlayPosition] = useState(null); 
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_AWS;
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const duration = 2000; // Duración de la animación en milisegundos


  // Controla el estado del DataTableComponent
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModalLineChart = () => setOpenModalLineChart(true);
  const handleCloseModalLineChart = () => setOpenModalLineChart(false);
  
 
    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    useEffect(() => {
        if (!map || !isMounted) return;
    
        const overlayElement = document.getElementById("popup");
        if (!overlayElement) {
            console.error("Overlay element not found!");
            return;
        }
    
        // Inicializa el overlay si aún no existe
        if (!overlayRef.current) {
            overlayRef.current = new Overlay({
                element: overlayElement,
                autoPan: false,
                autoPanAnimation: { duration: 250 },
            });
            map.addOverlay(overlayRef.current);
        }
    
        return () => {
            // Limpia el overlay al desmontar el componente
            if (overlayRef.current) {
                map.removeOverlay(overlayRef.current);
                overlayRef.current = null;
            }
        };
    }, [map, isMounted]);
    
    // Sincronizar el estado de visibilidad del popup con el DOM
    useEffect(() => {
        if (!overlayRef.current || !isMounted) return;
    
        const popupElement = document.getElementById("popup");
        if (!popupElement) {
            console.error("Popup element not found!");
            return;
        }
    
        popupElement.style.display = isPopupVisible ? "block" : "none";

        return () => {
            // Limpia la referencia al nodo del popup al desmontar el componente
            if (popupElement && popupElement.parentNode) {
                popupElement.style.display = "none";
            }
        };
    }, [isPopupVisible, isMounted]);

    //Función para animación sensor. 
    function flash(feature) {
        const start = Date.now();
        const flashGeom = feature.getGeometry().clone();
        const listenerKey = vectorLayerRef.current.on('postrender', animate);
    
        function animate(event) {
            const frameState = event.frameState;
            const elapsed = frameState.time - start;
    
            if (elapsed >= duration) {
                unByKey(listenerKey);
                return;
            }
    
            const vectorContext = getVectorContext(event);
            const elapsedRatio = elapsed / duration;
    
            // Radio de la animación
            const radius = easeOut(elapsedRatio) * 25 + 5;
            const opacity = easeOut(1 - elapsedRatio);
    
            const style = new Style({
                image: new CircleStyle({
                    radius: radius,
                    stroke: new Stroke({
                        color: `rgba(255, 0, 0, ${opacity})`,
                        width: 0.25 + opacity,
                    }),
                }),
            });
    
            vectorContext.setStyle(style);
            vectorContext.drawGeometry(flashGeom);
    
            // Usar el mapa desde el contexto
            map.render();
        }
    }

    function flashAllVisibleFeatures() {
        const features = vectorSourceRef.current.getFeatures();
        features.forEach((feature) => flash(feature));
    }

    useEffect(() => {

        if (vectorLayerRef.current) {
            vectorLayerRef.current.setProperties({ interactive: false });
        }

        const intervalId = setInterval(() => {
            flashAllVisibleFeatures();
        }, 1000); // Cada segundo
    
        return () => clearInterval(intervalId); // Limpia el intervalo al desmontar
    }, []);    

    // Inicializar la capa del mapa
    useEffect(() => {
        if (!vectorLayerRef.current) {
            vectorLayerRef.current = new VectorLayer({
                source: vectorSourceRef.current,
                style: (feature) => {
                    const isBuffer = feature.get('isBuffer');
                    if (isBuffer) {
                        // Estilo invisible para la geometría de interacción
                        return new Style({
                            image: new CircleStyle({
                                radius: 24, // 48 píxeles de diámetro (24 de radio)
                                fill: null,
                                stroke: null,
                            }),
                        });
                    }
                    // Estilo visual para los sensores
                    return new Style({
                        image: new Icon({
                            src: sensorIcon,
                            scale: 1, // Tamaño visual del ícono
                            anchor: [0.5, 0.5],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'fraction',
                        }),
                    });
                },
                title: 'Sensors',
                zIndex: 9999, // Siempre por encima
                properties: {isLegendExempt: true, interactive: false },
            });
            addLayer(vectorLayerRef.current);     
             // Escuchar evento addfeature para animar
            vectorSourceRef.current.on('addfeature', (e) => {
                flash(e.feature);
            });
 
            if (overlayRef.current) {
                map.addOverlay(overlayRef.current);
            }    
            // Cambiar el cursor al pasar sobre los sensores
            map.on("pointermove", (event) => {
                const hit = map.hasFeatureAtPixel(event.pixel, {
                    layerFilter: (layer) => layer === vectorLayerRef.current,
                });
                map.getTargetElement().style.cursor = hit ? "pointer" : "";
            });
        }
    }, [addLayer]);

    // Cargar proyectos IoT
    useEffect(() => {
        const fetchProjectsIot = async () => {
            // Cargar proyectos desde localStorage primero
            const savedProjects = localStorage.getItem('projectsIot');
            if (savedProjects) {
                setProjectsIot(JSON.parse(savedProjects));
                console.log("Projects loaded from localStorage:", JSON.parse(savedProjects));
            }
    
            const storedUserId = localStorage.getItem("authToken");
    
            // Si no hay `userId` en el estado, intenta recuperarlo desde el `localStorage`
            if (!userId && storedUserId) {
                setUserId(storedUserId);
            }
    
            const finalUserId = userId || storedUserId;
    
            if (!finalUserId) {
                console.error("No userId found.");
                return;
            }
    
            try {
                // Hacer la solicitud a la API
                const response = await fetch(`${API_BASE_URL}/api/projectsiot/user?userId=${finalUserId}`);
                if (response.ok) {
                    const data = await response.json();
                   // if (isMountedRef.current) {
                        console.log("Fetched IoT projects from API:", data);
                        setProjectsIot(data);
                        localStorage.setItem('projectsIot', JSON.stringify(data));
                   // }
                } else {
                    console.error("Failed to fetch IoT projects from API.");
                }
            } catch (error) {
                console.error("Error fetching IoT projects from API:", error);
            }
        };
    
        fetchProjectsIot();
    
        return () => {
            //isMountedRef.current = false;
        };
    }, [userId, API_BASE_URL, setUserId]);


    const fetchSensorsByProject = async (projectId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/sensors/${projectId}`);
            if (response.ok) {
                const sensors = await response.json();
    
                if (sensors.length > 0) {
                    // Centrar el mapa en el primer sensor
                    const { latitude, longitude } = sensors[0];
                    const center = fromLonLat([longitude, latitude]);
                    map.getView().setCenter(center); // Usar el mapa desde el contexto
                    map.getView().setZoom(14);
                }
    
                addSensorsToMap(sensors, projectId);
            } else {
                console.error(`Failed to fetch sensors for project ${projectId}`);
            }
        } catch (error) {
            console.error("Error fetching sensors:", error);
        }
    };
    

    // Agregar sensores al mapa
    const addSensorsToMap = (sensors, projectId) => {
        sensors.forEach(({ latitude, longitude }) => {
            const coordinates = fromLonLat([longitude, latitude]);
            const sensorFeature = new Feature({
                geometry: new Point(fromLonLat([longitude, latitude])),
            });
            sensorFeature.setProperties({
                projectId: projectId,
                coordinates: [longitude, latitude], // Save the original WGS84 coordinates
            });
            const bufferFeature = new Feature({
                geometry: new Point(coordinates),
                projectId: projectId,
                isBuffer: true,
            });
            vectorSourceRef.current.addFeature(sensorFeature);
            vectorSourceRef.current.addFeature(bufferFeature);
        });
    };

    
    const onCloseMenu = () => {
        setSelectedMenu(null);
        // if (overlayRef.current && overlayRef.current.getElement()) {
        //     overlayRef.current.setPosition(undefined);
        // }
        if (overlayRef.current) {
            overlayRef.current.setPosition(undefined);
        }
    }


    const removeSensorsFromMap = (projectId) => {
        const features = vectorSourceRef.current.getFeatures().filter(
            (feature) => feature.get("projectId") === projectId
        );
        features.forEach((feature) => vectorSourceRef.current.removeFeature(feature));
    };
    const handleProjectSelection = (projectId) => {
        setSensorVisibility((prev) => {
            const updatedVisibility = { ...prev, [projectId]: !prev[projectId] };
            if (updatedVisibility[projectId]) {
                fetchSensorsByProject(projectId); // Mostrar sensores
                onCloseMenu();
            } else {
                removeSensorsFromMap(projectId); // Quitar sensores
                if (overlayVisible) {
                    setOverlayVisible(false);
                    if (overlayRef.current) {
                        overlayRef.current.setPosition(undefined);
                    }
                }
            }
            return updatedVisibility;
        });
    };
    useEffect(() => {
        if (!map) return;

        const handlePointerMove = (event) => {
            const hit = map.hasFeatureAtPixel(event.pixel, {
                layerFilter: (layer) => layer === vectorLayerRef.current,
            });
            map.getTargetElement().style.cursor = hit ? "pointer" : "";
        };
        //setIsPopupVisible(true);
        const handleMapClick = (event) => {
            const features = map.getFeaturesAtPixel(event.pixel, {
                layerFilter: (layer) => layer === vectorLayerRef.current,
            });
    
            if (features && features.length > 0) {
                const feature = features[0];
                const { coordinates } = feature.getProperties();
    
                if (coordinates) {
                    const mapCoordinates = fromLonLat(coordinates);
                    console.log("Sensor WGS84 coordinates:", coordinates);
                    console.log("Sensor Map coordinates:", mapCoordinates);
    
                    setOverlayPosition(mapCoordinates); // Guarda las coordenadas del overlay
                    setIsPopupVisible(true); // Activa el popup y sincroniza la lógica
                    overlayRef.current.setPosition(mapCoordinates);
                }
            } else {
                setIsPopupVisible(false); // Oculta el popup
                if (overlayRef.current) {
                    overlayRef.current.setPosition(undefined);
                }
            }
        };
    
        map.on("pointermove", handlePointerMove);
        map.on("singleclick", handleMapClick);
        map.on('touchend', handleMapClick);

        return () => {
           map.un("pointermove", handlePointerMove);
            map.un("singleclick", handleMapClick);
            map.un('touchend', handleMapClick);
        };
    }, [map]);

    // Actualizar la posición del overlay al mover el mapa
    useEffect(() => {
        if (!map || !overlayRef.current) return;
    
        const updatePopupPosition = () => {
            if (overlayPosition) {
                overlayRef.current.setPosition(overlayPosition);
            }
        };
    
        map.on('moveend', updatePopupPosition); // Asegura que el popup se mantenga fijo
        map.on('movestart', () => {
            overlayRef.current.setPosition(undefined); // Opcional: ocultar mientras se mueve
        });
    
        return () => {
            map.un('moveend', updatePopupPosition);
            map.un('movestart', () => overlayRef.current.setPosition(undefined));
        };
    }, [map, overlayPosition]);
    

    return (
        <>
               {/* Contenedor del menú IoT */}
               <div
               className="container-window"
               style={{
                   display: selectedMenu === "iot" ? "block" : "none",
                   left: isMenuActive ? "255px" : "85px",
               }}
           >
               <div className="container-caption">
                   <div>IoT Projects</div>
                   <div onClick={onCloseMenu}>
                       <CancelIcon />
                   </div>
               </div>
               <div className="container-card-iot">
                   {projectsIot.length > 0 ? (
                       projectsIot.map((project, index) => (
                           <Card
                               key={project.projectIoTId || index}
                               sx={{ maxWidth: 350, marginBottom: "16px" }}
                           >
                               <CardHeader
                                   title={project.projectIoName || "Unnamed Project"}
                                   subheader={new Date(project.createdAt).toLocaleDateString()}
                                   sx={{
                                        '& .MuiCardHeader-title': {
                                            fontSize: '1.25rem', // Cambia el tamaño de la letra del título
                                        },
                                        '& .MuiCardHeader-subheader': {
                                            fontSize: '0.875rem', // Cambia el tamaño de la letra del subheader
                                        },
                                        paddingBottom: '0px',
                                     }}
                               />
                               <CardContent
                                    sx={{ paddingBottom: '0px' }}
                               >
                                   <Typography variant="body2" color="text.secondary">
                                       <b>Description:</b>{" "}
                                       {project.projectDescription || "No description provided"}
                                   </Typography>
                               </CardContent>
                               <CardActions disableSpacing>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={
                                               <Switch
                                                   checked={!!sensorVisibility[project.projectIoTId]}
                                                   onChange={() =>
                                                       handleProjectSelection(project.projectIoTId)
                                                   }
                                                   color="primary"
                                               />
                                           }
                                           label="Show Sensors"
                                           sx={{
                                                '& .MuiTypography-root': {
                                                    color: '#00a1ff',
                                                },
                                            }}
                                    
                                       />
                                   </FormGroup>
                               </CardActions>
                           </Card>
                       ))
                   ) : (
                       <Typography
                           variant="subtitle1"
                           style={{ textAlign: "center", marginTop: "20px", color: "#00a1ff" }}
                       >
                           No IoT projects found. Add a new project to get started.
                       </Typography>
                   )}
               </div>
           </div>

           {/* {isPopupVisible && ( */}
                <div 
                    id="popup" 
                    className={`ol-popup ${isPopupVisible ? "visible" : ""}`}
                    style={{ display: isPopupVisible ? "block" : "none" }}>
                    {/* <a href="#" id="popup-closer" className="ol-popup-closer"></a> */}
                    <p>Choose one option:</p>
                    <div id="popup-content">
                        <Button
                            color="primary"
                            size="small"
                            sx={{
                                marginTop: "5px",
                                marginLeft: "5px",
                            }}
                            startIcon={<TableChartOutlinedIcon />}
                            onClick={handleOpenModal}
                        >
                            Show Sensor Data
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            sx={{
                                marginTop: "5px",
                                marginLeft: "5px",
                            }}
                            startIcon={<InsertChartOutlinedRoundedIcon />}
                            onClick={handleOpenModalLineChart}
                        >
                            Show Line Chart
                        </Button>
                    </div>
                </div>
             {/* )} */}
   
           {/* DataTableComponent activado */}
           <DataTableComponentcopy open={openModal} onClose={handleCloseModal} />
           <LineChartWithLabelsComponentcopy open={openModalLineChart} onClose={handleCloseModalLineChart}/>
       </>
    );
};

export default IotMenuComponent;
