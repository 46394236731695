import { useState } from "react";
// import './DrawingTool.scss';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import {
 Tooltip,
 IconButton,
 Menu,
 MenuItem
} from '@mui/material';
import SearcherComponent from './SearcherComponent';
import GeoLocateComponent from "../GeoLocateComponent/GeoLocateComponent";
import NamePointComponent from "./NamePointComponent";

const MenuLocationToolsComponent = ({ userMail }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    return (
        <div className="tools">
            <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            title= "Data and chart options" 
        >
            <ExploreOutlinedIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                slotProps={{
                    paper: {
                      style: {
                        width: '56px', // Ancho del menú
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center', // Centra horizontalmente los ítems
                        alignItems: 'center',     // Centra verticalmente los ítems
                        padding: 0
                      }
                    }
                  }}
            >
                <MenuItem >
                    <Tooltip  arrow>
                    <div >
                        <GeoLocateComponent />
                    </div>
                    </Tooltip>
                </MenuItem>
                <MenuItem>
                    <Tooltip arrow>
                    <div>
                        <SearcherComponent sx={{ color:"#000"}}/>
                    </div>    
                    </Tooltip>
                </MenuItem>
                {/* <MenuItem>
                    <Tooltip arrow>
                    <div>
                        <NamePointComponent userMail={userMail} sx={{ color:"#000"}}/>
                    </div>    
                    </Tooltip>
                </MenuItem> */}
            </Menu>
        </div>
    );
}

export default MenuLocationToolsComponent;