import React, { useState, useEffect, useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { DataGrid } from "@mui/x-data-grid";
import { GridLoader } from "react-spinners";
import { AppContext } from "../../context/AppContextProvider";
import axios from "axios";
import "./MapTools.scss";

const DataTableComponentcopy = ({ open, onClose }) => {
    const [data, setData] = useState([]);
    const { dataSensor } = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    // Obtener datos de la API
    const fetchData = async () => {
        if (!dataSensor) {
            console.error("No data sensor available for this user.");
            return;
        }

        try {
            setLoading(true); // Indicar que los datos están cargando
            const API_SENSOR_BASE_URL = process.env.REACT_APP_API_SENSOR_URL;
            const response = await axios.get(
                `${API_SENSOR_BASE_URL}tableName=${dataSensor}&limit=100`
            );
            // Formatea los datos para incluir un campo `id` único, necesario para DataGrid
            const formattedData = response.data.map((item, index) => ({
                id: index, // Identificador único
                ...item,
                value: item["measure_value::double"] ?? item["measure_value::bigint"], // Prioriza double, luego bigint
            }));
            setData(formattedData);
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        } finally {
            setLoading(false); // Finaliza el estado de carga
        }
    };

    // Cargar datos al abrir el modal
    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open]);

    // Configuración de las columnas de la tabla
    const columns = [
        { field: "measure_name", headerName: "Measure", width: 150 },
        { field: "time", headerName: "Time", width: 200 },
        { field: "value", headerName: "Value", width: 150 },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) => {
                // Definir colores y estilos según el estado
                const statusColors = {
                    Normal: { color: "green", icon: "✔️" }, // Check verde
                    Caution: { color: "#f63e03 ", icon: "⚠️" }, // Triángulo naranja
                    Alert: { color: "red", icon: "❗" }, // Exclamación roja
                };

                const status = params.value || "Unknown"; // Manejar estados no definidos
                const { color, icon } = statusColors[status] || { color: "gray", icon: "❓" };

                return (
                    <div style={{ display: "flex", alignItems: "center", color }}>
                        <span style={{ marginRight: 5 }}>{icon}</span> {status}
                    </div>
                );
            },
        },
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className="custom-dialog">
            <DialogTitle>Sensors Data</DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <GridLoader size={30} color="#2881a7" />
                    </div>
                ) : (
                    <div style={{ height: 400, width: "100%" }}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            disableSelectionOnClick
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DataTableComponentcopy;
